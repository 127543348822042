<template>
  <el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" append-to-body>
    <div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
    <div class="user-list-box">
      <div class="search">
        <el-input size="small" icon="el-icon-search" v-model="search" @input="onSearch" placeholder="输入老师名字搜索" />
      </div>
      <div class="user-list-content u-f">
        <div class="u-f2 left">
          <div
              class="left-item"
              @click="onDataTap(index)"
              :class="dataIndex === index ? 'active' : ''"
              v-for="(item, index) in departments"
              :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="u-f4 right">
          <div class="list">
            <div v-if="teacher_list.length === 0">
              查找不到老师数据
            </div>
            <template v-for="(item, index) in teacher_list">
              <div
                  :key="item.id"
                  class="item"
                  :class="teacherSelected.includes(item.id) ? 'active' : ''"
                  @click="onSelectTeacher(item)"
              >
                {{ item.username }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
      <el-button @click="reset" size="small">重置</el-button>
      <div class="u-f-item">
        <!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
        <el-button type="primary" size="small" @click="onConfirm">确 定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import v2Teacher from "@/api/v2/teacher";
import _ from "lodash";

export default {
  name: 'VTeacherSelect',
  props: {
    value: Boolean
  },
  computed: {
    selectShow: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    selectShow(val) {
      if (val) {
        this.getDepartmentList()
      }
    }
  },
  data() {
    return {
      departments: [],
      currentDepartment: 0,
      teacher_list: [],
      dataIndex: 0,
      typeIndex: 0,
      teacherSelected: [],
      search: ''
    }
  },
  methods: {
    // 获取部门列表
    getDepartmentList() {
      this.$api.setting.sectionList({}).then(res => {
        if (res.data.code === 1) {
          this.departments = res.data.data[0].children
          this.currentDepartment = this.departments[this.dataIndex].id
          this.getTeacherList(this.currentDepartment)
        }
      });
    },
    // 获取老师列表
    async getTeacherList() {
      const resp = await v2Teacher.getListByDepartment(this.currentDepartment, this.search)
      this.teacher_list = resp.data
    },
    // 搜索
    onSearch: _.debounce(function () {
      this.getTeacherList(this.currentDepartment)
    }, 500),
    handleClose() {
    },
    reset() {},
    onDataTap(index) {
      this.dataIndex = index
      this.currentDepartment = this.departments[this.dataIndex].id
      this.getTeacherList()
    },
    onSelectTeacher(item) {
      this.teacherSelected = [item.id]
    },
    onConfirm() {
      this.$emit('select', this.teacherSelected, this.teacher_list.filter(_ => this.teacherSelected.includes(_.id)))
      this.selectShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  padding: 5px 10px;
}

.user-list-box {
  .user-list-head {
    padding: 10px 20px;
    border-bottom: 1px solid #eeeeee;
    .user-type-list {
      .user-type-item {
        color: #8d9da6;
        cursor: pointer;
      }
      .active {
        color: #007aff;
        position: relative;
      }
      .active::after {
        position: absolute;
        width: 25px;
        height: 2px;
        background-color: #007aff;
        content: '';
        bottom: -5px;
        left: 0;
      }
      span {
        color: #cccccc;
        margin: 0 8px;
      }
    }
  }
  .user-list-content {
    height: 40vh;
    .left {
      height: 98%;
      overflow-y: auto;
      border-right: 1px solid #eeeeee;
      padding-top: 5px;
      .left-item {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        color: #a4a4a4;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .active {
        background-color: #e4f1ff;
        color: #2d8cf0;
        position: relative;
      }
      .active:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 2px;
        content: '';
        background-color: #2d8cf0;
      }
    }
    .right {
      height: 37vh;
      padding: 10px 25px;
      overflow-y: auto;
      .list {
        display: flex;
        flex-wrap: wrap;
        .item {
          cursor: pointer;
          padding: 5px 15px;
          border-radius: 3px;
          background-color: #f3f3f3;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .active {
          background-color: #e4f1ff;
          color: #2d8cf0;
        }
      }
    }
  }
}
</style>