import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const v2PropertyWarehouse = {
    // 资产导入模板
    importTemplate() {
        return request({
            url: '/v1/school_api/property_warehouse/import_template',
            responseType: 'blob'
        })
    },
    // 资产导入
    import(file, mold) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('mold', mold)
        return request({
            url: '/v1/school_api/property_warehouse/import',
            method: 'post',
            data: fd,
        })
    }
}
export default v2PropertyWarehouse
