<template>
	<div id="supplies">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">资产管理</a></el-breadcrumb-item>
			</el-breadcrumb>
      <v-weburl-iframe class="content" url="property/warehouse" />
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import VImport from "@/components/import";
import v2PropertyWarehouse from "@/api/v2/property_warehouse";
import VTeacherSelect from "@/components/teacher-select";
import VTeacherSelectInput from "@/components/teacher-select/input";
import VSuppliesForm from "@/view/manage/asset/supplies-form";
import VWeburlIframe from "@/components/weburl-iframe";
export default {
	components: {
    VWeburlIframe,
    VSuppliesForm,
    VTeacherSelectInput,
    VTeacherSelect,
    VImport,
		index
	},
	data() {
		return {
			meun: ['固定资产管理', '耗材管理'],
			tabIndex: 1,
			storage: false,
      form: {},
			assetTypeList: [],
			addVisible: false,
			editVisible: false,
			name: '',
			pid: 0,
			typeId: '',
			total: 0,
			assetList: [],
			sousuo: '',
			page: 1,
			limit: 10,
			assetname: '',
			asseTypeId: [],
			storagetime: '',
			number: '',
			money: 0,
			amount:0,
			roomList:[],
			roomid:'',
			category_id:'',
			category_ids:'',
			units:'',
			editstorage:false,
			id:'',
			sid:'',
			tid:'',
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			nums: [],
			selectShow: false,
			selectList: [],
			isBnt:false,
			sectionList:[],
			unit_price:'',
			sku:'',
			supplier:'',
			origin:'',
			store_type:1,
			code:'',
			proof:'',
			user_id:'',
			school_department_id:'',
			purpose:'',
			years:[],
			yearval:'',
			xueqiList:[],
			year_id:'',
			semester_id:'',
			semester_ids:'',
			file: '',
			dVisible: false,
      importTemplate: () => v2PropertyWarehouse.importTemplate(),
      editDialog: false,
		};
	},
	mounted() {
		this.getAssetTypeList();
		this.getAssetList();
    this.getSectionList()
	},
	methods: {
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		// Import
		async Import(fileData){
      const resp = await v2PropertyWarehouse.import(fileData, 1)
      this.getAssetList()
      return resp
		},
		suaixuan(){
			this.getAssetList();
		},
		// 获取部门列表
		getSectionList(){
			this.$api.setting.sectionList({}).then(res=>{
				if(res.data.code===1){
					let data = res.data.data;
					let arr = [];
					for (let i in data) {
						arr.push(data[i])
					}
					this.sectionList = arr;
					this.sectionList = this.sectionList.concat(data[0].children);
				}
			})
		},
		handleClose1(done){
			this.typeIndex = 0;
			this.dataIndex = 0;
			done()
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		closeDialog(){
			this.addVisible = false;
			this.editVisible = false;
			this.storage = false;
			this.editstorage = false;
			this.reset();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getAssetList()
		},
		tabTap(index) {
			if(index===0){
				this.$router.push('/asset');
			}
			this.tabIndex = index;
		},
		handleClose(done) {
			done();
			this.reset();
		},
		typeChange(e){
			this.category_id = e[0];
			this.category_ids = e[1];
		},
		// 获取分类列表
		getAssetTypeList() {
			this.$api.asset.getAssetTypeList({filter: JSON.stringify({mold:1})}).then(res => {
				if (res.data.code === 1) {
					let data = res.data.data;
					for (let i in data) {
						data[i].isShow = false;
						data[i].isbtn = false;
						for (let j in data[i].children) {
							data[i].children[j].checked = true;
							data[i].children[j].isbtn = false;
						}
					}
					this.assetTypeList = data;
				}
			});
		},
		// 添加分类
		addtype(pid) {
			this.pid = pid;
			this.name = '';
			this.addVisible = true;
		},
		submitaddtype() {
			let data = {
				name: this.name,
				pid: this.pid,
				mold: 1
			};
			this.$api.asset.addAssetType(data).then(res => {
				if (res.data.code === 1) {
					this.$message.success('添加成功');
					this.addVisible = false;
					this.closeDialog();
					this.getAssetTypeList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		tapType(id){
			this.sid = id;
			this.page = 1;
			this.getAssetList();
		},
		tapType1(id){
			this.tid = id;
			this.page = 1;
			this.getAssetList();
		},
		// 编辑分类弹窗
		editShow(item) {
			this.pid = item.pid;
			this.name = item.name;
			this.typeId = item.id;
			this.editVisible = true;
		},
		submitaddedit() {
			let data = {
				name: this.name,
				pid: this.pid,
				mold: 1,
				id:this.typeId
			};
			this.$api.asset.editAssetType(data).then(res => {
				if (res.data.code === 1) {
					this.$message.success('修改成功');
					this.editVisible = false;
					this.closeDialog();
					this.getAssetTypeList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 删除分类
		delPlace(id) {
			let _this = this;
			_this
				.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.asset
						.delAssetType({
							id: id
						})
						.then(res => {
							if (res.data.code === 1) {
								_this.$message.success('删除成功');
								_this.getAssetTypeList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		// 获取资产列表
		getAssetList() {
			let data = {
				mold:1
			}
			if(this.sid){data.category_ids = this.sid}
			if(this.year_id){data.year_id = this.year_id}
			if(this.semester_ids){data.semester_id = this.semester_ids}
			this.$api.asset.getAssetList({
				page:this.page,
				limit:this.limit,
				filter: JSON.stringify(data)
			}).then(res => {
				if (res.data.code == 1) {
					this.assetList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		// 资产入库
		submit(){
			if(this.assetname==''||!this.assetname){return this.$message.error('请输入资产名称');}
			if(this.category_id==''||!this.category_id){return this.$message.error('请选择分类');}
			if(this.roomid==''||!this.roomid){return this.$message.error('请选择位置');}
			if(this.storagetime==''||!this.storagetime){return this.$message.error('请选择入库时间');}
			if(this.number==''||!this.number){return this.$message.error('请输入物品数量');}
			if(this.units==''||!this.units){return this.$message.error('请输入物品单位');}
			let data = {
				year_id:this.yearval,
				semester_id:this.semester_id,
				code:this.code,
				name:this.assetname,
				units:this.units,	
				origin:this.origin,
				storagetime:this.storagetime,
				mold:1,
				number:this.number,
				money:this.money,	
				category_id:this.category_id,
				category_ids:this.category_ids,
				school_place_room_id:this.roomid,
				user_id:this.user_id,
				store_type:this.store_type,
				purpose:this.purpose,
				sku:this.sku,
				supplier:this.supplier,
				amount:this.amount,
				school_department_id:this.school_department_id,
				proof:this.proof
			}
			this.$api.asset.assetStroage(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.reset()
					this.storage = false;
					this.closeDialog();
					this.getAssetList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
			
		},
		reset(){
			this.name = '';
			this.pid = '';
			this.assetname = '';
			this.storagetime = '';
			this.units = '';
			this.number = '';
			this.money = '';
			this.category_id = '';
			this.category_ids = '';
			this.asseTypeId = [];
			this.roomid = '';
			this.code = '';
			this.origin = '';
			this.user_id = '';
			this.store_type = 1;
			this.purpose = '';
			this.sku = '';
			this.supplier = '';
			this.amount = '';
			this.school_department_id = '';
			this.proof = '';
		},
		// 编辑资产
		openDialog(e){
      this.getSectionList();
      if (e === 0) {
        this.storage = true
        return
      }
      this.form = {
        ...e,
      }
      this.editDialog = true
		},
		editSubmit(){
			if(this.assetname==''||!this.assetname){return this.$message.error('请输入资产名称');}
			if(this.category_id==''||!this.category_id){return this.$message.error('请选择分类');}
			if(this.roomid==''||!this.roomid){return this.$message.error('请选择位置');}
			if(this.storagetime==''||!this.storagetime){return this.$message.error('请选择入库时间');}
			if(this.number==''||!this.number){return this.$message.error('请输入物品数量');}
			if(this.units==''||!this.units){return this.$message.error('请输入物品单位');}
			let data = {
				id:this.id,
				year_id:this.yearval,
				semester_id:this.semester_id,
				code:this.code,
				name:this.assetname,
				units:this.units,	
				origin:this.origin,
				storagetime:this.storagetime,
				mold:1,
				number:this.number,
				money:this.money,	
				category_id:this.category_id,
				category_ids:this.category_ids,
				school_place_room_id:this.roomid,
				user_id:this.user_id,
				store_type:this.store_type,
				purpose:this.purpose,
				sku:this.sku,
				supplier:this.supplier,
				amount:this.amount,
				school_department_id:this.school_department_id,
				proof:this.proof
			}
			this.$api.asset.assetEdit(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功');
					this.getAssetList();
					this.reset()
					this.editstorage = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss">
#supplies {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.72vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						cursor: pointer;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
