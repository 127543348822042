<template>
  <el-dialog title="资产入库" :visible.sync="visible" width="500px" @closed="onVisible">
    <div class="edit-box">
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          资产名称
        </div>
        <el-input v-model="form.name" placeholder="请输入资产名称" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          凭证号
        </div>
        <el-input v-model="form.proof" placeholder="请输入资产凭证号" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          采购方式
        </div>
        <el-input v-model="form.origin" placeholder="请输入采购方式" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          仓库类型
        </div>
        <el-radio-group v-model="form.store_type">
          <el-radio :label="1">总务仓库</el-radio>
          <el-radio :label="2">实训仓库</el-radio>
        </el-radio-group>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          资产分类
        </div>
        <el-cascader
            v-model="cate_ids"
            :props="{value:'id',label:'name'}"
            :options="cates"
            style="width: 75%;"
        />
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          规格型号
        </div>
        <el-input v-model="form.sn" placeholder="请输入规格型号" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          供货商
        </div>
        <el-input v-model="form.supplier" placeholder="请输入供货商" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          用途
        </div>
        <el-input v-model="form.purpose" placeholder="请输入用途" style="width: 75%;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          位置
        </div>
        <el-select v-model="form.school_place_room_id" placeholder="请选择位置" style="width: 75%;" filterable >
          <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          使用部门
        </div>
        <el-select v-model="form.school_department_id" filterable placeholder="请选择部门" style="width: 70%;">
          <el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          负责人
        </div>
        <v-teacher-select-input v-model="form.user_id" />
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          单价
        </div>
        <el-input v-model="form.money" placeholder="请输入单价" style="width: 40%;;"></el-input>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          物品数量
        </div>
        <el-input-number v-model="form.number"></el-input-number>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          物品单位
        </div>
        <el-input v-model="form.units" placeholder="请输入物品单位" style="width: 40%;"></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="onClose" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="onSubmit">确 定</el-button>
					</span>
  </el-dialog>
</template>

<script>
import VTeacherSelectInput from "@/components/teacher-select/input";
export default {
  name: 'VSuppliesForm',
  components: {VTeacherSelectInput},
  props: {
    value: Boolean,
    data: Object,
    cates: Array,
  },
  data() {
    return {
      form: {
        user_id: 0,
      },
      sectionList: [],
      roomList: [],
      cate_ids: [],
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set() {
        this.form = {}
        this.$emit('input', false)
      }
    }
  },
  watch: {
    visible(v) {
      if (v) {
        this.form = {
          ...this.data,
        }
        this.cate_ids = [this.data.category_id, this.data.category_ids]
        this.getPlaceRoomList()
      } else {
        this.$emit('input', false)
      }
    }
  },
  methods: {
    // 获取房间列表
    getPlaceRoomList() {
      this.$api.setting.getPlaceRoomList({}).then(res => {
        if (res.data.code === 1) {
          this.roomList = res.data.data.room.rows;
        }
      });
    },
    onVisible() {
    },
    onClose() {},
    onSubmit() {}
  }
}
</script>

<style scoped>

</style>