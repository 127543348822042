<template>
  <div class="u-f" style="width: 75%;">
    <el-input style="width: 90%" readonly :value="teachers.map(item => item.username).join(',')" />
    <v-teacher-select v-model="show" @select="onSelect" />
    <el-button style="margin-left: 10px;height: 40px;" @click="onOpen">选择</el-button>
  </div>
</template>

<script>
import VTeacherSelect from "@/components/teacher-select/index";
import v2Teacher from "@/api/v2/teacher";
export default {
  name: 'VTeacherSelectInput',
  components: {VTeacherSelect},
  props: {
    value: [String, Number]
  },
  data() {
    return {
      init: false,
      show: false,
      teachers: []
    }
  },
  watch: {
    value(v) {
      if (v) {
        console.log('show', this.value)
        v2Teacher.getInfo(this.value)
            .then(res => {
              this.teachers = [res.data]
            })
      }
    }
  },
  methods: {
    onOpen() {
      this.show = true
    },
    onSelect(data, info) {
      this.teachers = info
      this.$emit('input', data[0])
    }
  }
}
</script>

<style scoped>

</style>